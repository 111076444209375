<template>
  <b-card :title="$t('userVisits')">
    <div v-if="uservisit.length > 0">
      <app-timeline v-for="visit in uservisit" :key="visit.id">
        <!-- 12 INVOICES HAVE BEEN PAID -->

        <app-timeline-item
          class="mt-2"
          :variant="randomVariant()"
          :title="visit.plantname"
          :subtitle="visitSubtitle(visit)"
          :time="dayjs(visit.visitdate).locale(`${$i18n.locale}`).fromNow()"
        />
      </app-timeline>
    </div>
    <div v-else>
      <span> {{ $t("xNotFound1", { value: $t("visit") }) }}</span>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
import relativeTime from "dayjs/plugin/relativeTime";
export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  data: function () {
    return {
      tr: tr,
      az: az,
      ru: ru,
      dayjs: dayjs.extend(relativeTime),
      uservisit: {},
      variant: ["success", "secondary", "info", "warning"],
    };
  },
  async beforeCreate() {
    var user = getUserData();
    var { data } = await this.$http.get(
      `/PlantVisits/GetVisitsByCompanyid/${user.companyid}`
    );
    const userid = this.$route.params.id ? this.$route.params.id : user.id;
    this.uservisit = data.resultdata.filter((x) => x.userid == userid);
  },
  methods: {
    visitSubtitle(item) {
      return `Rapor ${item.report}`;
      item.fullname;
    },
    randomVariant() {
      return this.variant[Math.floor(Math.random() * 4)];
    },
  },
};
</script>
